<template>
  <div class="widgetContainer widgetContainer--center widgetContainer--scrollable yourOwnership">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="logoutAndRedirect" />
        <p class="title">
          {{ $t('addOwnerPercentage_NavTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form class="wise-form" v-model="formData" 
               @keyup.enter.native="submit()"
               @submit.prevent.native>
        <div class="borderWrapper yourOwnership__switch-wrapper">
          <p class="wise-form__header">
            {{ $t('addOwnerPercentage_Title') }}
          </p>
          <div class="wise-form__text-with-switch">
            <p class="wise-forn__info">
              {{ $t('addOwnerPercentage_Switch_Descriptions') }}
            </p>
            <el-switch
              v-model="greaterThan25"
              active-color="var(--branding)"
              inactive-color="#dfdfe4" />
          </div>
        </div>

        <el-form-item
          prop="percentage"
          v-if="greaterThan25"
          class="is-no-asterisk column-input"
          :label="$t('addOwnerPercentage_TextField_Descriptions')">
          <el-input
            type="number"
            v-model="formData.percentage"
            v-if="greaterThan25"
            class="is-no-asterisk"
            maxlength="3" />
        </el-form-item>

        <p class="yourOwnership__verify-description wise-form__info">
          {{ $t('addOwnerPercentage_Descriptions') }}
        </p>
      </el-form>
      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" :disabled="isFormInValid" @click="submit" class="el-button__brand brand">
          {{ $t('addOwnerPercentage_NextButton') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import businessMixin from '../../mixins/business';

export default {
  name: 'YourOwnership',
  mixins: [businessMixin],
  data() {
    return {
      formData: {
        percentage: null
      },
      greaterThan25: null,
      ownerDetails: null
    };
  },
  computed: {
    isFormInValid() {
      if (!this.greaterThan25) {
        return false;
      } else if (
        this.formData.percentage &&
        this.formData.percentage !== '' &&
        this.formData.percentage >= 25 &&
        this.formData.percentage <= 100
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    ...mapActions('kyb', ['fetchAllOwners', 'updateOwner']),
    afterBusiness() {
      this.getAllOwners(this.getSelectedBusiness.id);
    },
    getAllOwners(businessId) {
      const loader = this.showLoader();
      this.fetchAllOwners(businessId)
        .then((res) => {
          console.log(res);
          if (res.data.length !== 0) {
            /** @type {import('@m/kyb/store/state').kybState['owners'][0]}*/
            this.ownerDetails = res.data[0];
            if (this.ownerDetails.ownership != 0) {
              this.greaterThan25 = true;
              this.formData.percentage = this.ownerDetails.ownership;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    submit() {
      if(this.isFormInValid) {
        return;
      }
      if (!this.greaterThan25) {
        this.formData.percentage = 0;
        // return //TODO: Need to change
      }
      /** @type {import('@m/kyb/store/state').kybState['owners'][0]} */
      const owner = this.ownerDetails;
      if (owner) {
        const loader = this.showLoader();
        this.updateOwner({
          ownerId: owner.id,
          details: {
            businessId: this.getSelectedBusiness.id,
            ownership: '' + this.formData.percentage,
            title: 'other'
          }
        })
          .then(() => {
            this.$router.push('/kyb/ownersList');
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    }
  }
};
</script>

<style lang="scss">
.yourOwnership {
  .navigationHeader {
     min-height: 68px;
  }
  .widgetContainer__body {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  &__switch-wrapper {
    .wise-forn__info {
      font-size: 14px;
      line-height: 20px;
      padding-right: 20px;
    }

    .wise-form__header {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 8px;
    }

    .wise-form__text-with-switch {
      padding-bottom: 0;
    }
  }

  .wise-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__verify-description {
    padding-bottom: 60px;
    margin-top: auto;
  }

  .el-input-number.is-without-controls .el-input__inner {
    text-align: left;
  }
}
</style>
